import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

const Splash = (props) => {



  return (
    <>
    <div id="splash-component" className="d-flex align-items-center justify-content-center">
      <div>
        <h1><a href="https://rpag.com">Coming soon</a></h1>
      </div>
    </div>

    {/*
    <hr />
    <h4>Location</h4>
    <pre>{JSON.stringify(props.location, null, 2)}</pre>
    <h4>Match</h4>
    <pre>{JSON.stringify(props.match, null, 2)}</pre>
    <h4>Config</h4>
    <pre>{JSON.stringify(props.config, null, 2)}</pre>
    <h4>Data</h4>
    <pre>{JSON.stringify(props.data, null, 2)}</pre>
    */}

    </>
  );
}

export default Splash;





<Helmet>
    <meta charSet="utf-8" />
    <title>My Title</title>
    <link rel="canonical" href="http://mysite.com/example" />
</Helmet>

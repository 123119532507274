
const ListView = (props) => {

  return (
    <div>
      <h1>List View</h1>
      <h3>this is the new list view</h3>
      <h4>{props.data.name}</h4>
    </div>
  );
}

export default ListView;

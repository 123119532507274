import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

import LoginButton from "../components/LoginButton";
import LogoutButton from "../components/LogoutButton";
import Profile from "../components/Profile";

const Page = (props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={3} className="overflow-hidden">
            <p><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small></p>
            <hr />
            <ul>
              { props.config.site.map((obj, index) => (
                <li key={ 'link-' + index.toString() }>
                  <Link to={ obj.path }>{ obj.name }</Link>
                </li>
              ))}
            </ul>

            <hr />
            <Profile />

            {!isAuthenticated && (
              <LoginButton />
            )}

            {isAuthenticated && (
              <LogoutButton />
            )}

          </Col>
          <Col>
            <h1>Hello world</h1>
            <h2>x {props.data.name} x</h2>
            <p>Search term: {props.location.search}</p>
            <p>ID: {props.match.params.id}</p>
            { parse(props.data.content) }

          </Col>
        </Row>
      </Container>


      {/*
      <hr />
      <h4>Location</h4>
      <pre>{JSON.stringify(props.location, null, 2)}</pre>
      <h4>Match</h4>
      <pre>{JSON.stringify(props.match, null, 2)}</pre>
      <h4>Config</h4>
      <pre>{JSON.stringify(props.config, null, 2)}</pre>
      <h4>Data</h4>
      <pre>{JSON.stringify(props.data, null, 2)}</pre>
      */}

    </>
  );
}

export default Page;

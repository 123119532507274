// amazing tutorial
// https://learnwithparam.com/blog/basic-routing-in-react-router/

import React from "react";
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Page from "./views/Page";
import Splash from "./views/Splash";
import List from "./views/ListView";
import Dynamic from "./views/Dynamic";

const config = {
  siteName: 'RPAG Events',
  site: [
    { name: 'Home', exact: true, path: '/', type: 'Splash', content: '<h1>Home page</h1>' },
    // { name: 'About', exact: false, path: '/about', type: 'List', content: '<h1>About page</h1>' },
    // { name: 'Klint\'s a Dick', exact: false, path: '/klintydick', type: 'Page', content: '<h1>Hey wow!</h1><h3>You\'re a fucking dick</h3>' },
    // { name: 'Dashboard', exact: false, path: '/dashboard', type: 'Page', content: '<h1>Dashboard</h1>' },
    // { name: 'User', exact: true, path: '/user', type: 'Page', content: '<h1>Usertest</h1>' },
    // { name: 'User with ID', exact: true, path: '/user/:id', type: 'Page', content: '<h1>Usertest</h1>' },
  ]
}

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>{ config.siteName }</title>
      </Helmet>

      <Switch>
        {
          config.site.map(function(obj, index) {

            if(obj.type === 'Splash')
              return  (
                <Route exact={obj.exact} path={obj.path} key={ 'route' + index.toString() } render={(props) => (
                  <Splash {...props} config={config} data={obj} />
                )} />
              )

            else if(obj.type === 'List')
              return  (
                <Route exact={obj.exact} path={obj.path} key={ 'route' + index.toString() } render={(props) => (
                  <List {...props} config={config} data={obj} />
                )} />
              )

            else if(obj.type === 'Page')
              return  (
                <Route exact={obj.exact} path={obj.path} key={ 'route' + index.toString() } render={(props) => (
                  <Page {...props} config={config} data={obj} />
                )} />
              )

            else
              return (
                <Route render={(props) => (
                  <Dynamic {...props} data={obj} key={ 'route' + index.toString() } />
                )} />
              )

          })
        }

        {/* 404/route note found */}
        <Route render={(props) => ( <Dynamic {...props} /> )} />
      </Switch>
    </Router>
  );
}

export default App;
